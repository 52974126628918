<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <h4 class="mb-0">Pathway Information</h4>
    </div>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <!-- Form: Personal Info Form -->
      <b-row class="mt-1">
        <b-row v-if="false">
          <!-- Field: Birth Date -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Birth Date" label-for="birth-date">
              <flat-pickr
                v-model="pathwayDataInfo.dob"
                class="form-control"
                :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'Z' }"
                placeholder="YYYY-MM-DD"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Mobile -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Mobile" label-for="mobile">
              <b-form-input id="mobile" v-model="pathwayDataInfo.mobile" />
            </b-form-group>
          </b-col>

          <!-- Field: Website -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Website" label-for="website">
              <b-form-input id="website" v-model="pathwayDataInfo.website" />
            </b-form-group>
          </b-col>

          <!-- Field: Language -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Language" label-for="language">
              <v-select
                v-model="pathwayDataInfo.language"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="languageOptions"
                :clearable="false"
                input-id="language"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Gender -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Gender" label-for="gender" label-class="mb-1">
              <b-form-radio-group id="gender" v-model="pathwayDataInfo.gender" :options="genderOptions" value="male" />
            </b-form-group>
          </b-col>

          <!-- Field: Contact Options -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Contact Options" label-for="contact-options" label-class="mb-1">
              <b-form-checkbox-group
                id="contact-options"
                v-model="pathwayDataInfo.contactOptions"
                :options="contactOptionsOptions"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Field: Pathway Name -->
        <b-col cols="12" lg="4" md="6" xs="12">
          <b-form-group label="Name" label-for="pathway-name">
            <b-form-input id="pathway-name" required v-model="pathwayDataInfo.name" />
          </b-form-group>
        </b-col>

        <!-- Field: Visibility -->
        <b-col cols="12" lg="4" md="6" xs="12">
          <b-form-group label="Visibility" label-for="pathway-visibility">
            <v-select
              v-model="pathwayDataInfo.visibility"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="visibilityOptions"
              :clearable="false"
              required
              input-id="pathway-visibility"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col cols="12" lg="4" md="6" xs="12">
          <b-form-group label="Status" label-for="pathway-status">
            <v-select
              v-model="pathwayDataInfo.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :clearable="false"
              required
              input-id="pathway-status"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-2 w-100">
        <b-col cols="12" lg="12">
          <b-button
            variant="primary"
            class="float-right"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="doSaveChanges"
          >
            Save Changes
          </b-button>
          <b-button
            variant="outline-secondary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="$router.back()"
          >
            <feather-icon icon="ArrowLeftIcon" />
            Return
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton } from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { onUnmounted, ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import rosterStoreModule from '@/views/apps/roster/rosterStoreModule';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    ToastificationContent,
  },
  props: {
    pathwayData: {
      type: Object,
      required: true,
    },
  },
  watch: {
    pathwayData: function () {
      this.inheritSelects();
    },
  },
  methods: {
    inheritSelects() {
      if (this.pathwayData.status && !this.pathwayData.status.value) {
        this.pathwayDataInfo.status = this.statusOptions.filter((s) => s.value === this.pathwayData.status)[0];
      }

      if (this.pathwayData.visibility && !this.pathwayData.visibility.value) {
        this.pathwayDataInfo.visibility = this.visibilityOptions.filter((s) => s.value === this.pathwayData.visibility)[0];
      }
    },
    doSaveChanges() {
      const pwd = this.pathwayDataInfo;

      pwd.visibility = pwd.visibility.value;
      pwd.status = pwd.status.value;

      store
        .dispatch('app-roster/updatePathway', { pathwayId: pwd.id, pathwayData: pwd })
        .then((response) => {
          this.$emit('update:pathway-data', response.data);

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Pathway Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit pathway',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
  },
  mounted() {
    this.inheritSelects();
  },
  setup(props) {
    const pathwayDataInfo = ref(props.pathwayData);

    const PATHWAY_APP_STORE_MODULE_NAME = 'app-roster';

    // Register module
    if (!store.hasModule(PATHWAY_APP_STORE_MODULE_NAME))
      store.registerModule(PATHWAY_APP_STORE_MODULE_NAME, rosterStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PATHWAY_APP_STORE_MODULE_NAME)) store.unregisterModule(PATHWAY_APP_STORE_MODULE_NAME);
    });

    const statusOptions = [
      { label: 'Created', value: 'created' },
      { label: 'Pending Approval', value: 'pending_approval' },
      { label: 'Approved', value: 'approved' },
      { label: 'Live', value: 'live' },
      { label: 'Retired', value: 'retired' },
    ];

    const visibilityOptions = [
      { label: 'Internal', value: 'internal' },
      { label: 'Public', value: 'public' },
    ];

    return {
      pathwayDataInfo,
      statusOptions,
      visibilityOptions,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
