<template>
  <component :is="pathwayData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="pathwayData === undefined">
      <h4 class="alert-heading">Error fetching pathway data</h4>
      <div class="alert-body">
        No pathway found with this pathway id. Check
        <b-link class="alert-link" :to="{ name: 'apps-roster-pathway-list' }"> Pathway List </b-link>
        for other pathways.
      </div>
    </b-alert>

    <b-tabs v-if="pathwayData" pills>
      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Information</span>
        </template>
        <pathway-edit-tab-information :pathway-data.sync="pathwayData" class="mt-2 pt-75" />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import rosterStoreModule from '../rosterStoreModule';
import PathwayEditTabInformation from './PathwayEditTabInformation.vue';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    PathwayEditTabInformation,
  },
  setup() {
    const pathwayData = ref(null);

    const PATHWAY_APP_STORE_MODULE_NAME = 'app-roster';

    // Register module
    if (!store.hasModule(PATHWAY_APP_STORE_MODULE_NAME))
      store.registerModule(PATHWAY_APP_STORE_MODULE_NAME, rosterStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PATHWAY_APP_STORE_MODULE_NAME)) store.unregisterModule(PATHWAY_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch('app-roster/fetchPathway', { id: router.currentRoute.params.id })
      .then((response) => {
        pathwayData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          pathwayData.value = undefined;
        }
      });

    return {
      pathwayData,
    };
  },
};
</script>

<style></style>
